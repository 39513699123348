import "./index.css";
import "@eqengage/design-system-v2/dist/bundle.css";
import "@eqengage/design-system-v2/dist/components.css";
import "flag-icons/css/flag-icons.min.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { initI18n } from "./i18n";
import * as FullStory from "@fullstory/browser";
import dayjs from "dayjs";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import React from "react";
import { initRuntimeEnv } from "src/init-runtime-env";
import { initZendesk } from "./initZendesk";

const App = React.lazy(() => import("./App"));

initRuntimeEnv()
  .then(startReact)
  .catch(() => console.error("Failed to set runtime config"));

function startReact() {
  dayjs.extend(dayjsUtcPlugin);
  dayjs.extend(customParseFormatPlugin);
  console.log(`starting--------`)
  initI18n(window.__RUNTIME_CONFIG__.URL_LINGUAL_SERVICE);
  // initZendesk();
  
  if (process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID) {
    FullStory.init({
      orgId: process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID
    });
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
